/* Intro */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --xmd-viewport (min-width: 640px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-intro {
  position: relative;
  z-index: 0;
  padding: 6vh var(--RS-space-16) 4vh;
  display: flex;
  align-items: flex-end;
  height: auto;

  @media (--h-mobile-viewport) {
    height: auto;
    min-height: 100vh;
  }

  @media (--md-viewport) {
    padding: 12vh var(--RS-space-24) 4vh;
  }

  @media (--lg-viewport) {
    height: 100vh;
    min-height: unset;
    padding: 14vh var(--RS-space-32) 3vh;
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-intro__bg{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.13;
  transform: scale(.67);
  transform-origin: top left;
}

.RS-intro__bg-1{
  background-image: radial-gradient(ellipse at left top, var(--RS-cv-color) 0%, transparent 67%);
}

.RS-intro__bg-2{
  background-image: radial-gradient(ellipse at left top, var(--RS-nlp-color) 0%, transparent 67%);
}

.RS-intro__bg-3{
  background-image: radial-gradient(ellipse at left top, var(--RS-sd-color) 0%, transparent 67%);
}

.RS-intro__bg-4{
  background-image: radial-gradient(ellipse at left top, var(--RS-a-color) 0%, transparent 67%);
}

.RS-intro__bg-5{
  background-image: radial-gradient(ellipse at left top, var(--RS-ts-color) 0%, transparent 67%);
}


.RS-intro__container {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  margin: 0 auto;
  height: 100%;
  padding-top: 4vh;

  @media (--lg-viewport) {
    /* flex-direction: row-reverse; */
  }

}

.RS-intro__slider-nav{
  display: flex;
  /* gap: var(--RS-space-96); */
  max-width: 100%;
  margin-top: calc(var(--RS-space-16) + var(--RS-space-256) + var(--RS-space-64));
  margin-right: auto;
  user-select: none;
  flex-wrap: wrap;
  /* flex-direction: column; */
  

  @media (--lg-viewport) {
    margin-top: auto;
    flex-direction: row;
  }

}

.RS-intro__slider-nav-item{
  /* margin-right: var(--RS-space-96); */
  margin-bottom: calc(var(--RS-space-16) + var(--RS-space-8));
  font-weight: var(--RS-font-weight-variable-extra-bold);
  font-size: var(--RS-font-size-h3-desktop);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  @media (--lg-viewport) {
    margin-bottom: calc(var(--RS-space-32) + var(--RS-space-8));
  }

}









.RS-intro__word-slider{
  font-family: 'JetBrainsMono-Variable';
  transform-origin: bottom left;
  display: inline-block;
  
  font-size: var(--RS-font-size-h1-mono);
  letter-spacing: -0.5px;
  margin-left: var(--RS-space-2);

  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h1-mono-desktop);
    margin-left: var(--RS-space-6);
  }
}

.RS-intro__word-slider-item{
  opacity: 0;
  
  transform: translateY(var(--RS-space-8-n));
  transition: opacity var(--RS-anim-duration-md) linear, transform var(--RS-anim-duration-md) var(--RS-default-transition);
}

.RS-intro__word-slider-cv{
  color: var(--RS-cv-color);
}

.RS-intro__word-slider-nlp{
  color: var(--RS-nlp-color);
}

.RS-intro__word-slider-sd{
  color: var(--RS-sd-color);
}

.RS-intro__word-slider-a{
  color: var(--RS-a-color);
}

.RS-intro__word-slider-ts{
  color: var(--RS-ts-color);
}









.RS-intro__slider-nav-item{
  width: 50%;
  /* opacity: .71; */
  opacity: .5;
  position: relative;

  @media (--lg-viewport) {
    width: 27.5%;
  }

}

.RS-intro__slider-nav-item:before{
  content: '';
  width: 100%;
  height: calc(100% + var(--RS-space-12)*2);
  position: absolute;
  top: var(--RS-space-12-n);
  opacity: 0;
}

.RS-intro__slider-nav-item:nth-of-type(2){
  width: 50%;

  @media (--lg-viewport) {
  width: 45%;

  }

}

.RS-intro__slider-nav-item--active{
  opacity: 1;
}

.RS-intro__slider-nav-item--cv.RS-intro__slider-nav-item--active{
  color: var(--RS-cv-color);
}

.RS-intro__slider-nav-item--nlp.RS-intro__slider-nav-item--active{
  color: var(--RS-nlp-color);
}

.RS-intro__slider-nav-item--sd.RS-intro__slider-nav-item--active{
  color: var(--RS-sd-color);
}

.RS-intro__slider-nav-item--a.RS-intro__slider-nav-item--active{
  color: var(--RS-a-color);
}

.RS-intro__slider-nav-item--ts.RS-intro__slider-nav-item--active{
  color: var(--RS-ts-color);
}

.RS-intro__word-slider > span:nth-of-type(1){
  display: flex;
  opacity: 1;
}


.RS-intro__word-slider > span{
  display: none;
}

