/* Footer */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-footer {
  padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-32);
  font-weight: var(--RS-font-weight-variable-bold);

  @media (--md-viewport) {
    padding: var(--RS-space-96) var(--RS-space-32) var(--RS-space-32);
  }

}

.RS-footer__container {
  max-width: var(--RS-col-8);
  margin: 0 auto;
}

.RS-footer__copyright {
  font-size: var(--RS-font-size-disclaimer);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: var(--RS-font-weight-variable-bold);
  margin-left: auto;
}

.RS-footer__disclaimer {
  font-size: var(--RS-font-size-disclaimer);
  font-weight: var(--RS-font-weight-variable-bold);
  /* margin-top: var(--RS-space-16-n); */
  position: absolute;
  transform: translateY(-100%);
}

.RS-footer__navigation {
  
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: var(--RS-space-8) 0;

  @media (--md-viewport) {
    margin: var(--RS-space-48) 0;
    flex-direction: row;
  }

}

.RS-footer-col {
  font-size: var(--RS-font-size-h3);
  line-height: var(--RS-line-height-xlg);
  margin-bottom: var(--RS-space-12);
  display: flex;
  gap: var(--RS-space-12);

  @media (--md-viewport) {
    margin-bottom: 0;
    display: block;
  }

  @media (--xlg-viewport) {
    font-size: var(--RS-font-size-h3-desktop);
  }
}

.RS-footer-col:first-of-type{
  margin-bottom: var(--RS-space-32);

  @media (--md-viewport) {
    margin-bottom: var(--RS-space-12);
  }
}

.RS-footer__container > .RS-footer-col{
  margin: 0;
  display: flex;
}

.RS-footer-col .RS-link svg{
  margin-left: var(--RS-space-4);
}