/* Typography */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--RS-font-weight-variable-regular);
}

p{
  line-height: var(--RS-line-height-lg);
}

.RS-simple-link {
  text-decoration: underline;
  transition: color 350ms;
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-simple-link:hover {
  color: var(--RS-primary-button-color);
}

.RS-link {
  color: inherit;
  text-decoration: none;
  padding-bottom: var(--RS-space-2);
  position: relative;
}

.RS-link:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  left: 0;
  border-radius: var(--RS-space-8);
  transform-origin: 100%;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  background: var(--RS-black-color);
  position: absolute;
  transform: scaleX(0);
}

.RS-link:hover:before {
  transform-origin: 0;
  transform: scaleX(1);
}

.RS-link--active:before,
.RS-link--active:hover:before{
  @media (--md-viewport) {
    transform-origin: 0;
    transform: scaleX(.5);
  }
}

.RS-no-wrap{
  white-space: nowrap;
}

.RS-br-sm{
  display: initial;

  @media (--md-viewport) {
    display: none;
  }
}


.RS-br-md{

  @media (--sm-viewport) {
    display: none;
  }

  @media (--lg-viewport) {
    display: initial;
  }
}

.RS-br-lg{
  display: none;

  @media (--lg-viewport) {
    display: initial;
  }
}

.RS-inline-icon{
  font-size: 80%;
  line-height: 80%;
  display: inline-block;
  min-width: var(--RS-space-16);
  margin: 0 var(--RS-space-4);
}

.RS-inline-icon-external-link{
  font-size: 90%;
  line-height: 90%;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
}

.RS-inline-icon-external-link svg path{
  fill: var(--RS-primary-button-color);
}

.RS-font-weight-extra-bold{
  font-weight: var(--RS-font-weight-variable-extra-bold);
}

.RS-font-weight-bold{
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-font-weight-medium{
  font-weight: var(--RS-font-weight-variable-medium);
}

.RS-font-weight-regular{
  font-weight: var(--RS-font-weight-variable-regular);
}

.RS-font-style-italic{
  display: inline-flex;
  transform: skewX(-8deg);
}

.RS-privacy-policy h1,
.RS-privacy-policy h2,
.RS-privacy-policy h3{
  font-weight: var(--RS-font-weight-variable-bold);
  line-height: var(--RS-line-height-lg);
  margin: var(--RS-space-16) 0;
}

.RS-privacy-policy p{
  font-weight: var(--RS-font-weight-variable-medium);
  line-height: var(--RS-line-height-xlg);
}

.RS-privacy-policy ul{
  margin: var(--RS-space-16) 0;
  list-style-type: disc;
  font-weight: var(--RS-font-weight-variable-medium);
  line-height: var(--RS-line-height-xlg);
}