*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}

img, picture, video, canvas, svg {
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}

:root {
  --RS-space-1: 1px;
  --RS-space-2: 2px;
  --RS-space-4: 4px;
  --RS-space-6: 6px;
  --RS-space-8: 8px;
  --RS-space-12: 12px;
  --RS-space-16: 16px;
  --RS-space-24: 24px;
  --RS-space-32: 32px;
  --RS-space-48: 48px;
  --RS-space-64: 64px;
  --RS-space-96: 96px;
  --RS-space-128: 128px;
  --RS-space-192: 192px;
  --RS-space-256: 256px;
  --RS-space-1-n: -1px;
  --RS-space-2-n: -2px;
  --RS-space-4-n: -4px;
  --RS-space-6-n: -6px;
  --RS-space-8-n: -8px;
  --RS-space-12-n: -12px;
  --RS-space-16-n: -16px;
  --RS-space-24-n: -24px;
  --RS-space-32-n: -32px;
  --RS-space-48-n: -48px;
  --RS-space-64-n: -64px;
  --RS-space-96-n: -96px;
  --RS-space-128-n: -128px;
  --RS-space-192-n: -192px;
  --RS-space-256-n: -256px;
  --RS-col-1: 160px;
  --RS-col-2: 320px;
  --RS-col-3: 480px;
  --RS-col-4: 640px;
  --RS-col-5: 800px;
  --RS-col-6: 960px;
  --RS-col-7: 1120px;
  --RS-col-8: 1280px;
  --RS-white-color: #e8eaed;
  --RS-black-color: #01060e;
  --RS-light-grey-color: #dcdde0;
  --RS-dark-grey-color: #838485;
  --RS-graphite-color: #161e2b;
  --RS-cv-color: #d97c44;
  --RS-nlp-color: #c42d19;
  --RS-sd-color: #9a3eb1;
  --RS-a-color: #3e4bb1;
  --RS-ts-color: #31829c;
  --RS-brand-color-sample: #7977b9;
  --RS-anim-delay: 50ms;
  --RS-default-transition: cubic-bezier(.39, .575, .565, 1);
  --RS-bounce-transition: cubic-bezier(.175, .885, .32, 1.275);
  --RS-anim-duration-xs: 75ms;
  --RS-anim-duration-sm: .15s;
  --RS-anim-duration-md: .225s;
  --RS-anim-duration-lg: .35s;
  --RS-anim-duration-xlg: .6s;
  --RS-anim-intro-artwork-delay: .75s;
  --RS-anim-full-rotate-duration: 90s;
  --RS-line-height-sm: 1;
  --RS-line-height-md: 1.21;
  --RS-line-height-lg: 1.34;
  --RS-line-height-xlg: 1.55;
  --RS-font-size-h1: clamp(3.125rem, 2.8036rem + 1.4286vw, 4.5rem);
  --RS-font-size-h1-desktop: clamp(4.25rem, 3.7339rem + .6452vw, 4.5rem);
  --RS-font-size-h1-mono: clamp(2.8125rem, 2.4911rem + 1.4286vw, 4.1875rem);
  --RS-font-size-h1-mono-desktop: clamp(3.9375rem, 3.4214rem + .6452vw, 4.1875rem);
  --RS-font-size-h2: clamp(1.3125rem, 1.2468rem + .2922vw, 1.59375rem);
  --RS-font-size-h2-desktop: clamp(1.5rem, 1.2419rem + .3226vw, 1.625rem);
  --RS-font-size-h3: clamp(.9375rem, .9083rem + .1299vw, 1.0625rem);
  --RS-font-size-h3-desktop: clamp(1.0625rem, .9335rem + .1613vw, 1.125rem);
  --RS-font-size-top-nav: clamp(.875rem, .8458rem + .1299vw, 1rem);
  --RS-font-size-mobile-nav: clamp(2.625rem, 2.2772rem + 1.7391vw, 3.125rem);
  --RS-font-size-p: clamp(.9375rem, .9083rem + .1299vw, 1.0625rem);
  --RS-font-size-p-desktop: clamp(1.03125rem, .9022rem + .1613vw, 1.09375rem);
  --RS-font-size-label: clamp(.75rem, .7281rem + .0974vw, .84375rem);
  --RS-font-size-label-desktop: clamp(.8125rem, .6835rem + .1613vw, .875rem);
  --RS-font-size-stat: clamp(2.5rem, 2.2516rem + 1.1039vw, 3.5625rem);
  --RS-font-size-disclaimer: clamp(.75rem, .7354rem + .0649vw, .8125rem);
  --RS-font-weight-variable-regular: 375;
  --RS-font-weight-variable-medium: 550;
  --RS-font-weight-variable-bold: 650;
  --RS-font-weight-variable-extra-bold: 725;
  --RS-brand-bg-xy: calc(var(--RS-space-24)  - var(--RS-space-8));
  --RS-rotate-firefox-fix: 0deg;
  --RS-box-shadow: inset 5px -5px 13px 0px #ffffff21, inset 1px -1px 0px 0px #ffffffab, 1px 1px 0px 0px #dcdee136, 13px 13px 13px 1px #dcdee157;
  --RS-box-shadow-alt: 1px 1px 0px 0px #00000008, -13px 0px 13px 1px #0000000d;
}

@font-face {
  font-family: InstrumentSans-Variable;
  src: url("../InstrumentSans-Variable.e47ce2ea.woff2") format("woff2"), url("../InstrumentSans-Variable.5a7f4308.woff") format("woff"), url("../InstrumentSans-Variable.4473c37d.ttf") format("truetype");
  font-weight: 100 800;
  font-stretch: 75% 125%;
  font-display: swap;
}

@font-face {
  font-family: JetBrainsMono-Variable;
  src: url("../JetBrainsMono-Variable.7526198c.woff2") format("woff2"), url("../JetBrainsMono-Variable.f1bd923e.woff") format("woff"), url("../JetBrainsMono-Variable.b4220cb3.ttf") format("truetype");
  font-weight: 100 800;
  font-stretch: 75% 125%;
  font-display: swap;
}

p, h1, h2, h3, h4, h5, h6 {
  font-weight: var(--RS-font-weight-variable-regular);
}

p {
  line-height: var(--RS-line-height-lg);
}

.RS-simple-link {
  font-weight: var(--RS-font-weight-variable-bold);
  text-decoration: underline;
  transition: color .35s;
}

.RS-simple-link:hover {
  color: var(--RS-primary-button-color);
}

.RS-link {
  color: inherit;
  padding-bottom: var(--RS-space-2);
  text-decoration: none;
  position: relative;
}

.RS-link:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  border-radius: var(--RS-space-8);
  transform-origin: 100%;
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  background: var(--RS-black-color);
  position: absolute;
  left: 0;
  transform: scaleX(0);
}

.RS-link:hover:before {
  transform-origin: 0;
  transform: scaleX(1);
}

@media (min-width: 768px) {
  :is(.RS-link--active:before, .RS-link--active:hover:before) {
    transform-origin: 0;
    transform: scaleX(.5);
  }
}

.RS-no-wrap {
  white-space: nowrap;
}

.RS-br-sm {
  display: initial;
}

@media (min-width: 768px) {
  .RS-br-sm {
    display: none;
  }
}

@media (min-width: 480px) {
  .RS-br-md {
    display: none;
  }
}

@media (min-width: 1024px) {
  .RS-br-md {
    display: initial;
  }
}

.RS-br-lg {
  display: none;
}

@media (min-width: 1024px) {
  .RS-br-lg {
    display: initial;
  }
}

.RS-inline-icon {
  min-width: var(--RS-space-16);
  margin: 0 var(--RS-space-4);
  font-size: 80%;
  line-height: 80%;
  display: inline-block;
}

.RS-inline-icon-external-link {
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  font-size: 90%;
  line-height: 90%;
}

.RS-inline-icon-external-link svg path {
  fill: var(--RS-primary-button-color);
}

.RS-font-weight-extra-bold {
  font-weight: var(--RS-font-weight-variable-extra-bold);
}

.RS-font-weight-bold {
  font-weight: var(--RS-font-weight-variable-bold);
}

.RS-font-weight-medium {
  font-weight: var(--RS-font-weight-variable-medium);
}

.RS-font-weight-regular {
  font-weight: var(--RS-font-weight-variable-regular);
}

.RS-font-style-italic {
  display: inline-flex;
  transform: skewX(-8deg);
}

.RS-privacy-policy h1, .RS-privacy-policy h2, .RS-privacy-policy h3 {
  font-weight: var(--RS-font-weight-variable-bold);
  line-height: var(--RS-line-height-lg);
  margin: var(--RS-space-16) 0;
}

.RS-privacy-policy p {
  font-weight: var(--RS-font-weight-variable-medium);
  line-height: var(--RS-line-height-xlg);
}

.RS-privacy-policy ul {
  margin: var(--RS-space-16) 0;
  font-weight: var(--RS-font-weight-variable-medium);
  line-height: var(--RS-line-height-xlg);
  list-style-type: disc;
}

html {
  font-size: 100%;
}

body {
  font-family: InstrumentSans-Variable, system-ui, sans-serif;
  font-size: 16px;
  font-weight: var(--RS-font-weight-variable-regular);
  color: var(--RS-black-color);
  letter-spacing: 0;
  background-color: var(--RS-white-color);
  line-height: 1;
  transition: none;
  overflow-x: hidden;
  overflow-y: auto;
}

body > main {
  width: 100%;
  min-height: 1px;
  overflow: hidden;
}

a {
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

@media screen and (min--moz-device-pixel-ratio: 0) {
  :root {
    --RS-rotate-firefox-fix: .03deg;
  }

  svg, path {
    outline: 1px solid #fff0;
  }

  html {
    scrollbar-width: thin;
  }

  ::-moz-selection {
    color: var(--RS-light-grey-color);
    background: var(--RS-black-color);
  }
}

::selection {
  color: var(--RS-light-grey-color);
  background: var(--RS-black-color);
}

body::-webkit-scrollbar {
  width: var(--RS-space-6);
  height: var(--RS-space-4);
  background-color: var(--RS-space-black-color);
}

body::-webkit-scrollbar-thumb {
  background: var(--RS-light-grey-color);
  border-radius: 4px;
}

[role="button"] {
  cursor: pointer;
}

svg path, svg g {
  transform-box: fill-box;
  transform-origin: center;
}

img, video {
  width: 100%;
  height: auto;
  border-radius: inherit;
  outline: 1px solid #0000;
}

picture, img {
  border-radius: inherit;
}

ul {
  list-style: none;
}

ol {
  padding: var(--RS-space-32) 0;
  list-style: none;
}

.RS-photo--square img {
  aspect-ratio: 1 / 1;
}

.RS-photo--vertical img {
  aspect-ratio: .665 / 1;
}

.RS-photo--horizontal img {
  aspect-ratio: 1.5 / 1;
}

.RS-is-visible {
  opacity: 1 !important;
  transform: none !important;
}

.RS-section {
  width: 100%;
  padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-96);
  position: relative;
}

@media (min-width: 768px) {
  .RS-section {
    padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-128);
  }
}

@media (min-width: 1024px) {
  .RS-section {
    padding: var(--RS-space-96) var(--RS-space-32) var(--RS-space-128);
  }
}

.RS-section--inverted {
  background-color: var(--RS-black-color);
  color: var(--RS-white-color);
}

.RS-container {
  z-index: 1;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.RS-container.RS-container--narrow {
  max-width: var(--RS-col-4);
}

.RS-content {
  width: 100%;
  position: relative;
}

.RS-content--no-overflow {
  overflow: hidden;
}

.RS-content--centered {
  text-align: center;
}

.RS-content--spaced:not(:last-of-type) {
  margin: var(--RS-space-64) 0;
}

@media (min-width: 1024px) {
  .RS-content--spaced:not(:last-of-type) {
    margin: var(--RS-space-128) 0;
  }
}

.RS-content__group {
  margin: var(--RS-space-32) 0;
}

.RS-content__group--cols {
  flex-direction: row;
  display: flex;
  -webkit-mask-image: linear-gradient(#000 0% 80%, #0000 95% 100%);
  mask-image: linear-gradient(#000 0% 80%, #0000 95% 100%);
}

@media (min-width: 1024px) {
  .RS-content__group--cols {
    flex-direction: column;
    -webkit-mask-image: linear-gradient(#000 0% 75%, #0000 95% 100%);
    mask-image: linear-gradient(#000 0% 75%, #0000 95% 100%);
  }
}

.RS-content__figure {
  width: 100%;
  height: 100%;
  min-height: 20vh;
  border-radius: var(--RS-space-6);
  background-color: var(--RS-light-grey-color);
}

.RS-animation {
  opacity: .01;
  transition-timing-function: var(--RS-default-transition);
  transition-duration: var(--RS-anim-duration-xlg);
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
  transform-style: preserve-3d;
  transition-property: transform, opacity;
}

.RS-animation__slow {
  transition-duration: var(--RS-anim-duration-xlg);
}

.RS-animation.RS-animation--enter {
  opacity: 1;
  transform: translate3d(0, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-top {
  transform: translate3d(0, 8px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-bottom {
  transform: translate3d(0, -13px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-left {
  transform: translate3d(13px, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__text-to-right {
  transform: translate3d(-8px, 0, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-right {
  transform: translate3d(89px, -34px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-left {
  transform: translate3d(-89px, -34px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-top {
  transform: translate3d(0, 55px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-animation__image-to-bottom {
  transform: translate3d(0, -55px, 0) rotate(var(--RS-rotate-firefox-fix));
}

.RS-looped-animation, .RS-looped-animation:before, .RS-looped-animation:after {
  backface-visibility: hidden;
  will-change: transform;
  animation-play-state: paused !important;
}

.RS-looped-animation--running, .RS-looped-animation--running:before, .RS-looped-animation--running:after {
  animation-play-state: running !important;
}

.RS-top-bar {
  z-index: 666;
  width: 100%;
  padding: var(--RS-space-24);
  transition: transform var(--RS-anim-duration-lg) var(--RS-default-transition);
  backface-visibility: hidden;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  position: fixed;
  inset: 0 0 auto;
  transform: translate3d(0, 0, 0);
}

@media (min-width: 768px) {
  .RS-top-bar {
    padding: var(--RS-space-24) var(--RS-space-32);
  }
}

.RS-top-bar:before {
  content: "";
  width: inherit;
  height: 100%;
  z-index: -1;
  opacity: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-mask-image: linear-gradient(#000, #0000 100%);
  mask-image: linear-gradient(#000, #0000 100%);
}

.RS-top-bar--hidden {
  transform: translate3d(0, -100%, 0);
}

.RS-top-bar__container {
  height: var(--RS-space-32);
  flex-flow: row;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.RS-top-bar__brand {
  z-index: 1;
}

.RS-top-bar__logo {
  width: calc(var(--RS-space-64)  + var(--RS-space-16)  + var(--RS-space-4));
  height: calc(var(--RS-space-24)  - var(--RS-space-2));
  margin: 0 var(--RS-space-8) 0 0;
}

.RS-top-bar__nav {
  height: 100vh;
  width: 100vw;
  background: var(--RS-light-grey-color);
  top: var(--RS-space-24-n);
  left: var(--RS-space-24-n);
  justify-content: center;
  align-items: center;
  gap: var(--RS-space-32);
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  font-size: var(--RS-font-size-mobile-nav);
  flex-direction: column;
  display: none;
  position: fixed;
  transform: translateX(100%);
}

@media (min-width: 768px) {
  .RS-top-bar__nav {
    height: inherit;
    justify-content: flex-end;
    gap: calc(var(--RS-space-32)  + var(--RS-space-4));
    width: unset;
    font-size: inherit;
    background: unset;
    top: unset;
    left: unset;
    font-size: var(--RS-font-size-top-nav);
    flex-direction: row;
    margin-left: auto;
    display: flex;
    position: relative;
    transform: none;
  }
}

.RS-top-bar__nav--displayed {
  display: flex;
}

.RS-top-bar__nav--opened {
  transform: translateX(0%);
}

.RS-top-bar__nav:before {
  content: "";
  top: calc(var(--RS-space-24-n) * 1);
  right: calc(var(--RS-space-48-n) * 1);
  width: calc(100% + var(--RS-space-48) * 2);
  height: calc(100% + var(--RS-space-24) * 2);
  background: var(--RS-white-color);
  box-shadow: var(--RS-box-shadow);
  opacity: .98;
  border-radius: 0 0 0 var(--RS-space-32);
  display: none;
  position: absolute;
}

@media (min-width: 768px) {
  .RS-top-bar__nav:before {
    display: block;
  }
}

.RS-top-bar__nav > .RS-link {
  padding-bottom: var(--RS-space-4);
  opacity: 0;
  transform: translateY(var(--RS-space-32-n));
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  font-weight: var(--RS-font-weight-variable-bold);
  letter-spacing: .25px;
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link {
    opacity: 1;
    font-weight: var(--RS-font-weight-variable-bold);
    transform: none;
  }
}

.RS-top-bar__nav > .RS-link--visible {
  opacity: 1;
  transform: none;
}

.RS-top-bar__nav > .RS-link:first-of-type {
  transition-delay: calc(var(--RS-anim-delay) * 1);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:first-of-type {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(2) {
  transition-delay: calc(var(--RS-anim-delay) * 2);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(2) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(3) {
  transition-delay: calc(var(--RS-anim-delay) * 3);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(3) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(4) {
  transition-delay: calc(var(--RS-anim-delay) * 4);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(4) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(5) {
  transition-delay: calc(var(--RS-anim-delay) * 5);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(5) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(6) {
  transition-delay: calc(var(--RS-anim-delay) * 6);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(6) {
    transition-delay: unset;
  }
}

.RS-top-bar__nav > .RS-link:nth-of-type(7) {
  transition-delay: calc(var(--RS-anim-delay) * 7);
}

@media (min-width: 768px) {
  .RS-top-bar__nav > .RS-link:nth-of-type(7) {
    transition-delay: unset;
  }
}

.RS-top-bar__mobile-nav-icon {
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
  margin-left: auto;
  display: block;
}

@media (min-width: 768px) {
  .RS-top-bar__mobile-nav-icon {
    display: none;
  }
}

.RS-top-bar__mobile-nav-icon span {
  width: calc(var(--RS-space-32)  + var(--RS-space-1));
  height: var(--RS-space-4);
  background-color: var(--RS-black-color);
  margin: var(--RS-space-6) auto;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform-origin: center;
  display: block;
}

.RS-top-bar__mobile-nav-icon--active span:first-of-type {
  transform: rotateZ(45deg) translateY(calc(var(--RS-space-6)  + 1px));
}

.RS-top-bar__mobile-nav-icon--active span:nth-of-type(2) {
  transform: rotateZ(-45deg) translateY(calc(var(--RS-space-6-n)  - 1px));
}

.RS-button {
  width: 100%;
  color: var(--RS-white-color);
  border-radius: var(--RS-space-48);
  padding: 0 var(--RS-space-16);
  -webkit-tap-highlight-color: transparent;
  font-weight: var(--RS-font-weight-variable-bold);
  padding: var(--RS-space-16) var(--RS-space-48);
  isolation: isolate;
  background-image: radial-gradient(circle, #e8eaed 0% 50%, #e8eaed00 100%);
  outline: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 12px #e8eaed;
}

@media (min-width: 768px) {
  .RS-button {
    width: auto;
    min-width: calc(var(--RS-space-128)  + var(--RS-space-32));
  }
}

.RS-button.RS-button--active, .RS-button:hover {
  color: var(--RS-white-color);
  background-image: radial-gradient(circle, #e8eaed 0% 50%, #e8eaed00 100%);
}

.RS-button--inverted {
  color: var(--RS-black-color);
  background-color: var(--RS-light-grey-color);
}

.RS-button--inverted.RS-button--active, .RS-button--inverted:hover {
  color: var(--RS-black-color);
  background-color: var(--RS-white-color);
}

.RS-button.RS-button--active .RS-button__accent, .RS-button:hover .RS-button__accent {
  opacity: 1;
}

.RS-button--small {
  padding: var(--RS-space-12) var(--RS-space-24);
  min-width: unset;
}

.RS-button--ghost {
  background-color: unset;
  color: var(--RS-black-color);
  border: 1px solid var(--RS-dark-grey-color);
}

.RS-button--ghost.RS-button--active, .RS-button--ghost:hover {
  color: var(--RS-black-color);
  background-color: var(--RS-light-grey-color);
}

.RS-button > svg.fa-arrow-right {
  margin: 0 0 0 var(--RS-space-16);
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  font-size: clamp(1.25rem, 1.1994rem + .2532vw, 1.5rem);
  line-height: 1;
  transform: translate3d(0, 0, 0);
}

.RS-button.RS-button--nav {
  width: auto;
  max-width: none;
  background: unset;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-bold);
  padding: 0 0 var(--RS-space-4) 0;
}

@media (min-width: 768px) {
  .RS-button.RS-button--nav {
    color: var(--RS-white-color);
    padding: var(--RS-space-12);
    opacity: 1;
    background-image: radial-gradient(circle, #e8eaed 0% 50%, #e8eaed00 100%);
  }
}

@media (min-width: 768px) {
  :is(.RS-button.RS-button--nav.RS-button--active, .RS-button.RS-button--nav:hover) {
    color: var(--RS-white-color);
    background-image: radial-gradient(circle, #e8eaed 0% 50%, #e8eaed00 100%);
  }
}

.RS-button__accent {
  pointer-events: none;
  opacity: 1;
  transition: opacity var(--RS-anim-duration-md) var(--RS-default-transition), background-color var(--RS-anim-duration-md) var(--RS-default-transition);
  border-radius: 50%;
  display: block;
  position: absolute;
  -webkit-mask-image: radial-gradient(#000, #0000 67%);
  mask-image: radial-gradient(#000, #0000 67%);
}

@media (min-width: 768px) {
  .RS-button__accent {
    display: block;
  }
}

.RS-button--nav .RS-button__accent {
  display: none;
}

@media (min-width: 768px) {
  .RS-button--nav .RS-button__accent {
    display: block;
  }
}

.RS-button__accent--primary {
  width: 89%;
  height: 200%;
  background-color: var(--RS-black-color);
  bottom: -89%;
  left: -25%;
}

.RS-button__accent--secondary {
  width: 67%;
  height: 200%;
  background-color: var(--RS-black-color);
  top: -100%;
  right: -15%;
}

.RS-button__accent--tetriary {
  width: 200%;
  height: 200%;
  background-color: var(--RS-black-color);
  bottom: -33%;
  right: -100%;
}

.RS-button__accent--anim-pulse {
  animation: 18s linear infinite animButtonAccentPulse;
}

@keyframes animButtonAccentPulse {
  0%, 100% {
    transform: translate3d(0%, 0%, 0);
  }

  50% {
    transform: translate3d(2%, 2%, 0)scaleY(.89)scaleX(.67);
  }
}

.RS-button__accent:nth-of-type(4), .RS-button__accent:nth-of-type(5), .RS-button__accent:nth-of-type(6) {
  opacity: .67;
}

.RS-button--hero-1 .RS-button__accent {
  background-color: var(--RS-cv-color);
}

.RS-button--hero-2 .RS-button__accent {
  background-color: var(--RS-nlp-color);
}

.RS-button--hero-3 .RS-button__accent {
  background-color: var(--RS-sd-color);
}

.RS-button--hero-4 .RS-button__accent {
  background-color: var(--RS-a-color);
}

.RS-button--hero-5 .RS-button__accent {
  background-color: var(--RS-ts-color);
}

.RS-button__content {
  z-index: 2;
  gap: var(--RS-space-8);
  flex-direction: row;
  align-items: center;
  display: flex;
}

.RS-list {
  font-weight: var(--RS-font-weight-variable-bold);
  padding: var(--RS-space-32) 0 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.RS-list li {
  padding: 0 0 var(--RS-space-16) 0;
  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-md);
}

@media (min-width: 1024px) {
  .RS-list li {
    font-size: var(--RS-font-size-p-desktop);
  }
}

.RS-list--tiles {
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: var(--RS-space-16);
  flex-flow: wrap;
}

@media (min-width: 1024px) {
  .RS-list--tiles {
    gap: var(--RS-space-32);
    flex-wrap: nowrap;
  }
}

.RS-list--tiles li {
  padding: var(--RS-space-4);
  width: 100%;
  border-radius: var(--RS-space-6);
  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-md);
  background-color: var(--RS-white-color);
  box-shadow: var(--RS-box-shadow);
  border-radius: var(--RS-space-8);
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .RS-list--tiles li {
    font-size: var(--RS-font-size-p-desktop);
    padding: var(--RS-space-16);
  }
}

.RS-tile-icon {
  width: calc(var(--RS-space-32)  - var(--RS-space-8));
  height: calc(var(--RS-space-64)  - var(--RS-space-8));
}

@media (min-width: 1024px) {
  .RS-tile-icon {
    width: var(--RS-space-32);
    height: var(--RS-space-32);
  }
}

.RS-list--tiles-square li {
  width: calc(var(--RS-space-64)  - var(--RS-space-12));
  height: calc(var(--RS-space-64)  - var(--RS-space-12));
}

@media (min-width: 1024px) {
  .RS-list--tiles-square li {
    width: calc(var(--RS-space-64)  + var(--RS-space-8));
    height: calc(var(--RS-space-64)  + var(--RS-space-8));
  }
}

.RS-list--tiles-desc li {
  background-color: unset;
  box-shadow: unset;
  gap: var(--RS-space-12);
  width: auto;
}

.RS-list--tiles-desc li .RS-tile-icon-wrapper {
  background-color: var(--RS-white-color);
  box-shadow: var(--RS-box-shadow);
  border-radius: var(--RS-space-8);
  padding: var(--RS-space-16);
  border-radius: var(--RS-space-6);
}

.RS-list--tiles-desc li .RS-tile-icon-wrapper .RS-tile-icon {
  width: calc(var(--RS-space-24)  - var(--RS-space-4));
  height: calc(var(--RS-space-24)  - var(--RS-space-4));
}

@media (min-width: 1024px) {
  .RS-list--tiles-desc li .RS-tile-icon-wrapper .RS-tile-icon {
    width: var(--RS-space-24);
    height: var(--RS-space-24);
  }
}

.RS-tile-desc {
  text-align: left;
  justify-content: center;
  align-items: flex-start;
  gap: var(--RS-space-2);
  flex-direction: column;
  display: flex;
}

.RS-tile-desc span:first-of-type {
  font-size: var(--RS-font-size-label-desktop);
  font-weight: var(--RS-font-weight-variable-extra-bold);
}

.RS-tile-desc span:last-of-type {
  opacity: .5;
  text-transform: uppercase;
  font-size: var(--RS-font-size-disclaimer);
  letter-spacing: .5px;
  font-weight: var(--RS-font-weight-variable-extra-bold);
  transform-origin: 0 0;
  transform: scale(.8);
}

.RS-deploy-icon {
  width: 32px;
}

.RS-list--tiles-deploy .RS-tile-icon {
  width: calc(var(--RS-space-48)  - var(--RS-space-16));
  height: calc(var(--RS-space-48)  - var(--RS-space-16));
}

@media (min-width: 1024px) {
  .RS-list--tiles-deploy .RS-tile-icon {
    width: var(--RS-space-48);
    height: var(--RS-space-48);
  }
}

.RS-list--tiles-deploy li:before {
  content: "";
  width: var(--RS-space-1);
  height: var(--RS-space-96);
  top: var(--RS-space-96-n);
  z-index: -1;
  opacity: .13;
  transform-origin: bottom;
  border-right: 2px dashed #000;
  display: flex;
  position: absolute;
}

.RS-list--tiles-deploy li:first-of-type:before {
  transform: rotateZ(45deg);
}

.RS-list--tiles-deploy li:nth-of-type(2):before {
  transform: rotateZ(22.5deg);
}

.RS-list--tiles-deploy li:nth-of-type(4):before {
  transform: rotateZ(-22.5deg);
}

.RS-list--tiles-deploy li:nth-of-type(5):before {
  transform: rotateZ(-45deg);
}

.RS-marquee {
  height: var(--RS-space-48);
}

@media (min-width: 1024px) {
  .RS-marquee {
    padding: var(--RS-space-64) 0;
  }
}

.RS-marquee-wrapper {
  padding: var(--RS-space-48) 0;
  width: var(--RS-col-8);
  cursor: pointer;
}

@media (min-width: 1024px) {
  .RS-marquee-wrapper {
    padding: var(--RS-space-64) 0;
    width: 100%;
  }
}

.RS-marquee__item {
  justify-content: center;
  align-items: center;
  display: flex;
}

.RS-marquee__item svg {
  height: var(--RS-space-24);
  max-width: var(--RS-space-96);
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .RS-marquee__item svg {
    height: var(--RS-space-32);
    max-width: var(--RS-space-128);
  }
}

.RS-marquee__item--lg-h svg {
  height: var(--RS-space-32);
}

@media (min-width: 1024px) {
  .RS-marquee__item--lg-h svg {
    height: var(--RS-space-48);
  }
}

.RS-marquee__item--xl-h svg {
  height: var(--RS-space-48);
}

@media (min-width: 1024px) {
  .RS-marquee__item--xl-h svg {
    height: var(--RS-space-64);
  }
}

.RS-marquee__item--sm-h svg {
  height: var(--RS-space-16);
}

@media (min-width: 1024px) {
  .RS-marquee__item--sm-h svg {
    height: var(--RS-space-24);
  }
}

.RS-marquee__item--lg-w svg {
  max-width: calc(var(--RS-space-96)  + var(--RS-space-8));
}

@media (min-width: 1024px) {
  .RS-marquee__item--lg-w svg {
    max-width: calc(var(--RS-space-128)  + var(--RS-space-32));
  }
}

.keen-slider__slide {
  min-width: 100%;
  max-width: 100%;
  transform: translate3d(0, 0, 0);
}

.RS-background {
  width: 50vw;
  height: 50vw;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: -1;
  position: absolute;
  top: -10vh;
  left: 10vh;
}

.RS-background--content {
  width: 33vw;
  height: 33vw;
  top: 8.3vw;
  left: 16.6vw;
}

.RS-background > div {
  width: 100%;
  height: 100%;
  opacity: .13;
  animation: 12s linear infinite animBgPulse;
  position: absolute;
}

.RS-background--content > div {
  opacity: .08;
}

@keyframes animBgPulse {
  0%, 100% {
    transform: translate3d(0%, 0%, 0);
  }

  50% {
    transform: translate3d(5%, 5%, 0)scaleY(.89)scaleX(.67);
  }
}

.RS-background > div:first-of-type {
  background-image: radial-gradient(circle, #d97c44 0%, #d97c4400 50% 100%);
}

.RS-background > div:nth-of-type(2) {
  background-image: radial-gradient(circle, #c42d19 0%, #c42d1900 50% 100%);
  left: 25%;
}

.RS-background > div:nth-of-type(3) {
  background-image: radial-gradient(circle, #9a3eb1 0%, #9a3eb100 50% 100%);
  top: -25%;
}

.RS-background > div:nth-of-type(4) {
  background-image: radial-gradient(circle, #3e4bb1 0%, #3e4bb100 50% 100%);
  top: 25%;
}

.RS-background > div:nth-of-type(5) {
  background-image: radial-gradient(circle, #31829c 0%, #31829c00 50% 100%);
  left: -25%;
}

.RS-header--centered {
  text-align: center;
}

@media (min-width: 768px) {
  .RS-header--wide {
    max-width: var(--RS-col-7);
  }
}

.RS-header--spaced {
  margin: var(--RS-space-48) auto;
}

@media (min-width: 768px) {
  .RS-header--spaced {
    margin: 0 auto var(--RS-space-64);
  }
}

@media (min-width: 768px) {
  .RS-header--social {
    max-width: var(--RS-col-6);
  }
}

.RS-header--subpage-main {
  padding-top: 4vh;
}

.RS-header__headline {
  margin: 0 0 var(--RS-space-12);
  font-size: var(--RS-font-size-h3);
  text-transform: uppercase;
  letter-spacing: .15em;
  display: inline-flex;
}

@media (min-width: 1280px) {
  .RS-header__headline {
    font-size: var(--RS-font-size-h3);
  }
}

.RS-header__description {
  font-size: var(--RS-font-size-h1);
  line-height: var(--RS-line-height-md);
  font-weight: var(--RS-font-weight-variable-bold);
}

@media (min-width: 1280px) {
  .RS-header__description {
    font-size: var(--RS-font-size-h1-desktop);
    line-height: var(--RS-line-height-md);
  }
}

.RS-header__description + .RS-header__description {
  margin: var(--RS-space-32) 0 0 0;
}

.RS-header__description + .RS-header__description--secondary, .RS-header__description + .RS-button {
  margin: var(--RS-space-48) 0 0 0;
}

.RS-header__description--secondary {
  font-size: var(--RS-font-size-h2);
  line-height: var(--RS-line-height-md);
  font-weight: var(--RS-font-weight-variable-bold);
}

@media (min-width: 1280px) {
  .RS-header__description--secondary {
    font-size: var(--RS-font-size-h2-desktop);
  }
}

.RS-header__description--centered {
  justify-content: center;
  align-items: center;
  gap: var(--RS-space-8);
  display: flex;
}

.RS-header__headline + .RS-header__description--secondary {
  margin: var(--RS-space-24) 0 0 0;
}

.RS-header__description-link {
  color: inherit;
  transition: opacity var(--RS-anim-duration-sm) var(--RS-default-transition);
  display: inline-flex;
  position: relative;
}

.RS-header__description-link:hover {
  opacity: 1;
  box-shadow: inset 0 0 #0000;
}

.RS-header__description-link:before {
  content: "";
  width: 100%;
  height: var(--RS-space-2);
  bottom: var(--RS-space-2-n);
  background-color: var(--RS-pure-white-color);
  transform-origin: 0;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition);
  display: block;
  position: absolute;
  left: 0;
}

.RS-header__description-link:hover:before {
  transform-origin: 100%;
  background-color: var(--RS-primary-button-color);
  transform: scaleX(0);
}

.RS-header .RS-header__button {
  margin: var(--RS-space-32) 0 0;
}

@media (min-width: 768px) {
  .RS-header .RS-header__button {
    margin: var(--RS-space-48) 0 0;
  }
}

.RS-header__description {
  transition-duration: calc(var(--RS-anim-duration-xlg)  + var(--RS-anim-duration-lg));
}

.RS-header .RS-header__link, .RS-header__artwork {
  transition-duration: 1.5s;
}

.RS-header-secondary-icon {
  transform: translateY(var(--RS-space-6));
  display: inline-flex;
}

.RS-header-secondary-icon > svg {
  height: calc(var(--RS-space-24)  + var(--RS-space-2));
  width: calc(var(--RS-space-24)  + var(--RS-space-2));
}

@media (min-width: 768px) {
  .RS-header-secondary-icon > svg {
    height: calc(var(--RS-space-32)  + var(--RS-space-2));
    width: calc(var(--RS-space-32)  + var(--RS-space-2));
  }
}

.RS-header__description--secondary + p {
  font-size: var(--RS-font-size-h3-desktop);
  font-weight: var(--RS-font-weight-variable-medium);
  margin-top: var(--RS-space-12);
}

.RS-article {
  width: 100%;
  max-width: var(--RS-col-6);
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.RS-article--intro {
  max-width: var(--RS-col-8);
}

.RS-article--centered {
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.RS-article--block {
  display: block;
}

.RS-article--cols {
  gap: var(--RS-space-24);
  align-items: center;
}

@media (min-width: 1024px) {
  .RS-article--cols {
    gap: var(--RS-space-48);
    flex-direction: row;
  }
}

@media (min-width: 1024px) {
  .RS-article--reverse {
    flex-direction: row-reverse;
  }
}

.RS-article--wide {
  max-width: 100%;
}

@media (min-width: 1024px) {
  .RS-article--wide {
    max-width: var(--RS-col-8);
  }
}

.RS-article > svg {
  height: auto;
  margin: 0 0 var(--RS-space-48);
}

@media (min-width: 768px) {
  .RS-article > svg {
    margin: 0 0 var(--RS-space-64);
  }
}

@media (min-width: 1024px) {
  .RS-article > svg {
    margin: 0;
  }
}

.RS-article + .RS-article {
  margin-top: var(--RS-space-96);
}

.RS-intro {
  z-index: 0;
  padding: 6vh var(--RS-space-16) 4vh;
  height: auto;
  align-items: flex-end;
  display: flex;
  position: relative;
}

@media (max-height: 414px) and (max-width: 1024px) {
  .RS-intro {
    height: auto;
    min-height: 100vh;
  }
}

@media (min-width: 768px) {
  .RS-intro {
    padding: 12vh var(--RS-space-24) 4vh;
  }
}

@media (min-width: 1024px) {
  .RS-intro {
    height: 100vh;
    min-height: unset;
    padding: 14vh var(--RS-space-32) 3vh;
    margin: 0 0 var(--RS-space-32);
  }
}

.RS-intro__bg {
  width: 100%;
  height: 100%;
  opacity: .13;
  transform-origin: 0 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(.67);
}

.RS-intro__bg-1 {
  background-image: radial-gradient(ellipse at left top, var(--RS-cv-color) 0%, transparent 67%);
}

.RS-intro__bg-2 {
  background-image: radial-gradient(ellipse at left top, var(--RS-nlp-color) 0%, transparent 67%);
}

.RS-intro__bg-3 {
  background-image: radial-gradient(ellipse at left top, var(--RS-sd-color) 0%, transparent 67%);
}

.RS-intro__bg-4 {
  background-image: radial-gradient(ellipse at left top, var(--RS-a-color) 0%, transparent 67%);
}

.RS-intro__bg-5 {
  background-image: radial-gradient(ellipse at left top, var(--RS-ts-color) 0%, transparent 67%);
}

.RS-intro__container {
  z-index: 1;
  width: 100%;
  max-width: var(--RS-col-8);
  min-height: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
  padding-top: 4vh;
  display: flex;
  position: relative;
}

.RS-intro__slider-nav {
  max-width: 100%;
  margin-top: calc(var(--RS-space-16)  + var(--RS-space-256)  + var(--RS-space-64));
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: wrap;
  margin-right: auto;
  display: flex;
}

@media (min-width: 1024px) {
  .RS-intro__slider-nav {
    flex-direction: row;
    margin-top: auto;
  }
}

.RS-intro__slider-nav-item {
  margin-bottom: calc(var(--RS-space-16)  + var(--RS-space-8));
  font-weight: var(--RS-font-weight-variable-extra-bold);
  font-size: var(--RS-font-size-h3-desktop);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media (min-width: 1024px) {
  .RS-intro__slider-nav-item {
    margin-bottom: calc(var(--RS-space-32)  + var(--RS-space-8));
  }
}

.RS-intro__word-slider {
  transform-origin: 0 100%;
  font-family: JetBrainsMono-Variable;
  font-size: var(--RS-font-size-h1-mono);
  letter-spacing: -.5px;
  margin-left: var(--RS-space-2);
  display: inline-block;
}

@media (min-width: 1280px) {
  .RS-intro__word-slider {
    font-size: var(--RS-font-size-h1-mono-desktop);
    margin-left: var(--RS-space-6);
  }
}

.RS-intro__word-slider-item {
  opacity: 0;
  transform: translateY(var(--RS-space-8-n));
  transition: opacity var(--RS-anim-duration-md) linear, transform var(--RS-anim-duration-md) var(--RS-default-transition);
}

.RS-intro__word-slider-cv {
  color: var(--RS-cv-color);
}

.RS-intro__word-slider-nlp {
  color: var(--RS-nlp-color);
}

.RS-intro__word-slider-sd {
  color: var(--RS-sd-color);
}

.RS-intro__word-slider-a {
  color: var(--RS-a-color);
}

.RS-intro__word-slider-ts {
  color: var(--RS-ts-color);
}

.RS-intro__slider-nav-item {
  width: 50%;
  opacity: .5;
  position: relative;
}

@media (min-width: 1024px) {
  .RS-intro__slider-nav-item {
    width: 27.5%;
  }
}

.RS-intro__slider-nav-item:before {
  content: "";
  width: 100%;
  height: calc(100% + var(--RS-space-12) * 2);
  top: var(--RS-space-12-n);
  opacity: 0;
  position: absolute;
}

.RS-intro__slider-nav-item:nth-of-type(2) {
  width: 50%;
}

@media (min-width: 1024px) {
  .RS-intro__slider-nav-item:nth-of-type(2) {
    width: 45%;
  }
}

.RS-intro__slider-nav-item--active {
  opacity: 1;
}

.RS-intro__slider-nav-item--cv.RS-intro__slider-nav-item--active {
  color: var(--RS-cv-color);
}

.RS-intro__slider-nav-item--nlp.RS-intro__slider-nav-item--active {
  color: var(--RS-nlp-color);
}

.RS-intro__slider-nav-item--sd.RS-intro__slider-nav-item--active {
  color: var(--RS-sd-color);
}

.RS-intro__slider-nav-item--a.RS-intro__slider-nav-item--active {
  color: var(--RS-a-color);
}

.RS-intro__slider-nav-item--ts.RS-intro__slider-nav-item--active {
  color: var(--RS-ts-color);
}

.RS-intro__word-slider > span:first-of-type {
  opacity: 1;
  display: flex;
}

.RS-intro__word-slider > span {
  display: none;
}

.RS-footer {
  padding: var(--RS-space-64) var(--RS-space-24) var(--RS-space-32);
  font-weight: var(--RS-font-weight-variable-bold);
}

@media (min-width: 768px) {
  .RS-footer {
    padding: var(--RS-space-96) var(--RS-space-32) var(--RS-space-32);
  }
}

.RS-footer__container {
  max-width: var(--RS-col-8);
  margin: 0 auto;
}

.RS-footer__copyright {
  font-size: var(--RS-font-size-disclaimer);
  text-transform: uppercase;
  letter-spacing: .1em;
  font-weight: var(--RS-font-weight-variable-bold);
  margin-left: auto;
}

.RS-footer__disclaimer {
  font-size: var(--RS-font-size-disclaimer);
  font-weight: var(--RS-font-weight-variable-bold);
  position: absolute;
  transform: translateY(-100%);
}

.RS-footer__navigation {
  margin: var(--RS-space-8) 0;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 768px) {
  .RS-footer__navigation {
    margin: var(--RS-space-48) 0;
    flex-direction: row;
  }
}

.RS-footer-col {
  font-size: var(--RS-font-size-h3);
  line-height: var(--RS-line-height-xlg);
  margin-bottom: var(--RS-space-12);
  gap: var(--RS-space-12);
  display: flex;
}

@media (min-width: 768px) {
  .RS-footer-col {
    margin-bottom: 0;
    display: block;
  }
}

@media (min-width: 1280px) {
  .RS-footer-col {
    font-size: var(--RS-font-size-h3-desktop);
  }
}

.RS-footer-col:first-of-type {
  margin-bottom: var(--RS-space-32);
}

@media (min-width: 768px) {
  .RS-footer-col:first-of-type {
    margin-bottom: var(--RS-space-12);
  }
}

.RS-footer__container > .RS-footer-col {
  margin: 0;
  display: flex;
}

.RS-footer-col .RS-link svg {
  margin-left: var(--RS-space-4);
}

.RS-cookie-bar {
  left: var(--RS-space-8);
  bottom: var(--RS-space-8);
  z-index: 666;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: 0;
  transition: transform var(--RS-anim-duration-xlg) var(--RS-default-transition), opacity var(--RS-anim-duration-md) var(--RS-default-transition);
  transform: translate3d(0, var(--RS-space-32), 0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  width: calc(100% - var(--RS-space-8) * 2);
  position: fixed;
}

@media (min-width: 480px) {
  .RS-cookie-bar {
    width: auto;
    left: var(--RS-space-32);
    bottom: var(--RS-space-32);
  }
}

.RS-cookie-bar.RS-cookie-bar--visible {
  pointer-events: all;
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.RS-cookie-bar__box {
  padding: var(--RS-space-12) var(--RS-space-16);
  color: var(--RS-black-color);
  background-color: var(--RS-white-color);
  border: 1px solid var(--RS-black-color);
  border-radius: 16px;
}

.RS-cookie-bar__content {
  align-items: center;
  gap: var(--RS-space-12);
  padding: 0 var(--RS-space-8);
  flex-direction: column;
  display: flex;
}

@media (min-width: 480px) {
  .RS-cookie-bar__content {
    gap: var(--RS-space-24);
    flex-direction: row;
  }
}

.RS-cookie-bar__title {
  margin: 0 0 var(--RS-space-8);
  font-size: 32px;
  line-height: 40px;
}

@media (min-width: 768px) {
  .RS-cookie-bar__title {
    font-size: 40px;
    line-height: 48px;
  }
}

.RS-cookie-bar__title-icon {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  fill: currentColor;
  display: inline-block;
  position: relative;
  top: -1px;
}

@media (min-width: 768px) {
  .RS-cookie-bar__title-icon {
    width: 40px;
    height: 48px;
    top: -2px;
  }
}

.RS-cookie-bar__actions {
  gap: var(--RS-space-16);
  align-items: center;
  display: flex;
}

/*# sourceMappingURL=index.a95ccf0e.css.map */
