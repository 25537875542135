
@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-list {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-weight: var(--RS-font-weight-variable-bold);
  position: relative;
  padding: var(--RS-space-32) 0 0 0;

  @media (--md-viewport) {
    /* width: auto;
    min-width: calc(var(--RS-space-128) + var(--RS-space-32)); */
  }
}

.RS-list li{
  padding: 0 0 var(--RS-space-16) 0;

  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-md);

  

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-p-desktop);
  }

}

.RS-list--tiles{
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
  width: 100%;
  gap: var(--RS-space-16);
  flex-wrap: wrap;

  
  @media (--lg-viewport) {
    flex-wrap: nowrap;
    gap: var(--RS-space-32);
  }
}

.RS-list--tiles li{
  padding: var(--RS-space-4);
  width: 100%;
  border-radius: var(--RS-space-6);

  font-size: var(--RS-font-size-p);
  line-height: var(--RS-line-height-md);

  background-color: var(--RS-white-color);
  box-shadow: var(--RS-box-shadow);

  border-radius: var(--RS-space-8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;

  position: relative;

  @media (--lg-viewport) {
    font-size: var(--RS-font-size-p-desktop);
    padding: var(--RS-space-16);
  }
}

.RS-tile-icon{
  width: calc(var(--RS-space-32) - var(--RS-space-8));
  height: calc(var(--RS-space-64) - var(--RS-space-8));

  @media (--lg-viewport) {
    width: var(--RS-space-32);
    height: var(--RS-space-32);
  }
}

.RS-list--tiles-square li{

  width: calc(var(--RS-space-64) - var(--RS-space-12));
  height: calc(var(--RS-space-64) - var(--RS-space-12));

  @media (--lg-viewport) {
    width: calc(var(--RS-space-64) + var(--RS-space-8));
    height: calc(var(--RS-space-64) + var(--RS-space-8));
  }
}

.RS-list--tiles-desc li{
  background-color: unset;
  box-shadow: unset;
  gap: var(--RS-space-12);
  width: auto;
}

.RS-list--tiles-desc li .RS-tile-icon-wrapper{
  background-color: var(--RS-white-color);
  box-shadow: var(--RS-box-shadow);
  border-radius: var(--RS-space-8);
  padding: var(--RS-space-16);
  border-radius: var(--RS-space-6);

}

.RS-list--tiles-desc li .RS-tile-icon-wrapper .RS-tile-icon{
  width: calc(var(--RS-space-24) - var(--RS-space-4));
  height: calc(var(--RS-space-24) - var(--RS-space-4));

  @media (--lg-viewport) {
    width: var(--RS-space-24);
    height: var(--RS-space-24);  
  }
}

.RS-tile-desc{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  gap: var(--RS-space-2);
}

.RS-tile-desc span:first-of-type{
  font-size: var(--RS-font-size-label-desktop);
  font-weight: var(--RS-font-weight-variable-extra-bold);
}

.RS-tile-desc span:last-of-type{
 opacity: 0.5;
 text-transform: uppercase;
 font-size: var(--RS-font-size-disclaimer);
 letter-spacing: .5;
 font-weight: var(--RS-font-weight-variable-extra-bold);
 transform: scale(.8);
 transform-origin: top left;
}

/* .RS-list--tiles-desc:last-of-type{
  mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0% 34%, transparent 85% 100%);
} */

.RS-deploy-icon{
  width: 32px;
}

.RS-list--tiles-deploy .RS-tile-icon{
  width: calc(var(--RS-space-48) - var(--RS-space-16));
  height: calc(var(--RS-space-48) - var(--RS-space-16));

  @media (--lg-viewport) {
    width: var(--RS-space-48);
    height: var(--RS-space-48);  
  }
}

.RS-list--tiles-deploy li:before{
  content: '';
  position: absolute;
  display: flex;
  width: var(--RS-space-1);
  height: var(--RS-space-96);
  top: var(--RS-space-96-n);
  /* background: red; */
  border-right: 2px dashed black;
  z-index: -1;
  opacity: .13;
  transform-origin: bottom;
  
  /* mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 0% 34%, transparent 50% 100%); */
}

.RS-list--tiles-deploy li:nth-of-type(1):before{
  transform: rotateZ(45deg);
}

.RS-list--tiles-deploy li:nth-of-type(2):before{
  transform: rotateZ(22.5deg);
}

.RS-list--tiles-deploy li:nth-of-type(4):before{
  transform: rotateZ(-22.5deg);
}

.RS-list--tiles-deploy li:nth-of-type(5):before{
  transform: rotateZ(-45deg);
}
