
@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--RS-white-color);
  border-radius: var(--RS-space-48);
  /* box-shadow: inset 0 0 0 2px var(--RS-primary-button-color); */
  /* background-color: var(--RS-black-color); */
  /* background-image: radial-gradient(circle, rgba(1,6,14,1) 0%, rgba(1,6,14,1) 50%, rgba(1,6,14,0) 100%); */
  background-image: radial-gradient(circle, rgba(232, 234, 237,1) 0%, rgba(232, 234, 237,1) 50%, rgba(232, 234, 237,0) 100%);

  padding: 0 var(--RS-space-16);
  /* height: 48px; */
  -webkit-tap-highlight-color: transparent;
  outline: none;
  font-weight: var(--RS-font-weight-variable-bold);
  position: relative;
  padding: var(--RS-space-16) var(--RS-space-48);

  isolation: isolate;
  
  overflow: hidden;

  box-shadow: inset 0px 0px 12px 0px rgba(232, 234, 237, 1);

  @media (--md-viewport) {
    width: auto;
    min-width: calc(var(--RS-space-128) + var(--RS-space-32));
  }
}

.RS-button.RS-button--active,
.RS-button:hover {
  color: var(--RS-white-color);
  background-image: radial-gradient(circle, rgba(232, 234, 237,1) 0%, rgba(232, 234, 237,1) 50%, rgba(232, 234, 237,0) 100%);

}


.RS-button--inverted{
  color: var(--RS-black-color);
  background-color: var(--RS-light-grey-color);
}

.RS-button--inverted.RS-button--active,
.RS-button--inverted:hover{
  color: var(--RS-black-color);
  background-color: var(--RS-white-color);
}

.RS-button.RS-button--active .RS-button__accent,
.RS-button:hover .RS-button__accent{
  opacity: 1;
}


.RS-button--small{
  padding: var(--RS-space-12) var(--RS-space-24);
  min-width: unset;
}

.RS-button--ghost{
  background-color: unset;
  color: var(--RS-black-color);
  border: 1px solid var(--RS-dark-grey-color);
}

.RS-button--ghost.RS-button--active,
.RS-button--ghost:hover{
  color: var(--RS-black-color);
  background-color: var(--RS-light-grey-color);
}


/* .RS-button > span {
  position: relative;
  display: inline-block;
  line-height: var(--RS-line-height-md);
  font-size: clamp(1.125rem, 1.1123rem + 0.0633vw, 1.1875rem);
} */

.RS-button > svg.fa-arrow-right {
  margin: 0 0 0 var(--RS-space-16);
  font-size: 24px;
  font-size: clamp(1.25rem, 1.1994rem + 0.2532vw, 1.5rem);
  line-height: 1;
  transition: transform var(--RS-anim-duration-md) var(--RS-default-transition);
  transform: translate3d(0, 0, 0);
}


.RS-button.RS-button--nav{
  width: auto;
  max-width: none;
  background: unset;
  color: var(--RS-black-color);
  font-weight: var(--RS-font-weight-variable-bold);
  padding: 0 0 var(--RS-space-4) 0;

  @media (--md-viewport) {
    background-image: radial-gradient(circle, rgba(232, 234, 237,1) 0%, rgba(232, 234, 237,1) 50%, rgba(232, 234, 237,0) 100%);

    color: var(--RS-white-color);
    padding: var(--RS-space-12);
    opacity: 1;
  }
}

.RS-button.RS-button--nav.RS-button--active,
.RS-button.RS-button--nav:hover {
  @media (--md-viewport) {
    color: var(--RS-white-color);
    background-image: radial-gradient(circle, rgba(232, 234, 237,1) 0%, rgba(232, 234, 237,1) 50%, rgba(232, 234, 237,0) 100%);
  }
}

.RS-button__accent{
  position: absolute;
  border-radius: 50%;
  /* z-index: -1; */
  mask-image: radial-gradient(rgba(0, 0, 0, 1), transparent 67%);
  pointer-events: none;
  display: block;
  opacity: 1;
  transition: opacity var(--RS-anim-duration-md) var(--RS-default-transition), background-color var(--RS-anim-duration-md) var(--RS-default-transition);
  /* transition: opacity var(--RS-anim-duration-md) var(--RS-default-transition); */

  @media (--md-viewport) {
    display: block;
  }
}

.RS-button--nav .RS-button__accent{
  display: none;

  @media (--md-viewport) {
    display: block;
  }
}

/* .RS-button__accent--primary{
  width: 50%;
  height: 100%;
  top: -50%;
  left: 12.5%;
  background: var(--RS-white-color);
}

.RS-button__accent--secondary{
  width: 67%;
  height: 100%;
  bottom: -33%;
  right: -15%;
  background: var(--RS-white-color);
}

.RS-button__accent--tetriary{
  width: var(--RS-space-96);
  height: var(--RS-space-64);
  bottom: -100%;
  right: var(--RS-space-16);
  background: var(--RS-white-color);
} */


.RS-button__accent--primary{
  width: 89%;
  height: 200%;
  bottom: -89%;
  left: -25%;
  background-color: var(--RS-black-color);
}

.RS-button__accent--secondary{
  width: 67%;
  height: 200%;
  top: -100%;
  right: -15%;
  background-color: var(--RS-black-color);
}

.RS-button__accent--tetriary{
  width: 200%;
  height: 200%;
  bottom: -33%;
  right: -100%;
  background-color: var(--RS-black-color);
}


.RS-button__accent--anim-pulse{
  animation: animButtonAccentPulse 18s linear infinite;
}

@keyframes animButtonAccentPulse {
  0%,100%{
    transform: translate3d(0%, 0%, 0);
  }
  50% {
    transform: translate3d(2%, 2%, 0) scaleY(.89) scaleX(.67);
  }
}




.RS-button__accent:nth-of-type(4),
.RS-button__accent:nth-of-type(5),
.RS-button__accent:nth-of-type(6){
  opacity: .67;
}

.RS-button--hero-1 .RS-button__accent{
  background-color: var(--RS-cv-color);
}

.RS-button--hero-2 .RS-button__accent{
  background-color: var(--RS-nlp-color);
}

.RS-button--hero-3 .RS-button__accent{
  background-color: var(--RS-sd-color);
}

.RS-button--hero-4 .RS-button__accent{
  background-color: var(--RS-a-color);
}

.RS-button--hero-5 .RS-button__accent{
  background-color: var(--RS-ts-color);
}



.RS-button__content{
  z-index: 2;
  display: flex;
  gap: var(--RS-space-8);
  flex-direction: row;
  align-items: center;
}

