/* Background */

@custom-media --h-mobile-viewport (max-height: 414px) and (max-width: 1024px);
@custom-media --h-desktop-viewport (min-height: 720px) and (min-width: 1280px);
@custom-media --xs-viewport (min-width: 376px);
@custom-media --sm-viewport (min-width: 480px);
@custom-media --md-viewport (min-width: 768px);
@custom-media --lg-viewport (min-width: 1024px);
@custom-media --xlg-viewport (min-width: 1280px);
@custom-media --2xlg-viewport (min-width: 1920px);

.RS-background {
  position: absolute;
  width: 50vw;
  height: 50vw;
  top: -10vh;
  left: 10vh;
  pointer-events: none;
  user-select: none;
  z-index: -1;
  /* background: red; */
  @media (--md-viewport) {
  }
}

.RS-background--content{
  width: 33vw;
  height: 33vw;
  top: 8.3vw;
  left: 16.6vw;
}

.RS-background > div{
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .13;
  animation: animBgPulse 12s linear infinite;
}

.RS-background--content > div{
  opacity: .08;
}

@keyframes animBgPulse {
  0%,100%{
    transform: translate3d(0%, 0%, 0);
  }
  50% {
    transform: translate3d(5%, 5%, 0) scaleY(.89) scaleX(.67);
  }
}


.RS-background > div:nth-of-type(1){
  background-image: radial-gradient(circle, rgba(217, 124, 68, 1) 0%, rgba(217, 124, 68, 0) 50%, rgba(217, 124, 68, 0) 100%);
}

.RS-background > div:nth-of-type(2){
  left: 25%;
  background-image: radial-gradient(circle, rgba(196, 45, 25, 1) 0%, rgba(196, 45, 25, 0) 50%, rgba(196, 45, 25, 0) 100%);
}

.RS-background > div:nth-of-type(3){
  top: -25%;
  background-image: radial-gradient(circle, rgba(154, 62, 177, 1) 0%, rgba(154, 62, 177, 0) 50%, rgba(154, 62, 177, 0) 100%);
}

.RS-background > div:nth-of-type(4){
  top: 25%;
  background-image: radial-gradient(circle, rgba(62, 75, 177, 1) 0%, rgba(62, 75, 177, 0)  50%, rgba(62, 75, 177, 0)  100%);
}

.RS-background > div:nth-of-type(5){
  left: -25%;
  background-image: radial-gradient(circle, rgba(49, 130, 156, 1) 0%, rgba(49, 130, 156, 0) 50%, rgba(49, 130, 156, 0) 100%);
}